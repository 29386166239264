<template>
  <base-section id="img-change-to-grey">
    <base-section-heading
      title="HOWELLCARE INDUSTRIES: our team"
      icon="mdi-account-outline"
      outlined
    >
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, in! Asperiores, impedit libero. Veniam rerum saepe unde nihil possimus quibusdam esse accusamus mollitia magni fuga.
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="n in 4"
          :key="n"
          cols="6"
          md="3"
        >
          <v-hover>
            <template #default="{ hover }">
              <base-img
                :height="$vuetify.breakpoint.mdAndUp ? 400 : 200"
                :src="require(`@/assets/team-${n}.jpg`)"
                color="red"
                flat
                tile
              >
                <v-expand-transition>
                  <base-img
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-h2 white--text"
                    style="height: 100%;"
                    :src="require(`@/assets/team-${n}.jpg`)"
                  />
                </v-expand-transition>
              </base-img>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionImgChangeToGrey',
  }
</script>
